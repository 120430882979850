export const countries = [
	{
		label: "Albania",
		alpha2: "AL",
		alpha3: "ALB",
		numeric: 8,
		latitude: 41,
		longitude: 20,
	},
	{
		label: "Algeria",
		alpha2: "DZ",
		alpha3: "DZA",
		numeric: 12,
		latitude: 28,
		longitude: 3,
	},
	{
		label: "American Samoa",
		alpha2: "AS",
		alpha3: "ASM",
		numeric: 16,
		latitude: -14.3333,
		longitude: -170,
	},
	{
		label: "Andorra",
		alpha2: "AD",
		alpha3: "AND",
		numeric: 20,
		latitude: 42.5,
		longitude: 1.6,
	},
	{
		label: "Angola",
		alpha2: "AO",
		alpha3: "AGO",
		numeric: 24,
		latitude: -12.5,
		longitude: 18.5,
	},
	{
		label: "Anguilla",
		alpha2: "AI",
		alpha3: "AIA",
		numeric: 660,
		latitude: 18.25,
		longitude: -63.1667,
	},
	{
		label: "Antarctica",
		alpha2: "AQ",
		alpha3: "ATA",
		numeric: 10,
		latitude: -90,
		longitude: 0,
	},
	{
		label: "Antigua and Barbuda",
		alpha2: "AG",
		alpha3: "ATG",
		numeric: 28,
		latitude: 17.05,
		longitude: -61.8,
	},
	{
		label: "Argentina",
		alpha2: "AR",
		alpha3: "ARG",
		numeric: 32,
		latitude: -34,
		longitude: -64,
	},
	{
		label: "Armenia",
		alpha2: "AM",
		alpha3: "ARM",
		numeric: 51,
		latitude: 40,
		longitude: 45,
	},
	{
		label: "Aruba",
		alpha2: "AW",
		alpha3: "ABW",
		numeric: 533,
		latitude: 12.5,
		longitude: -69.9667,
	},
	{
		label: "Australia",
		alpha2: "AU",
		alpha3: "AUS",
		numeric: 36,
		latitude: -27,
		longitude: 133,
	},
	{
		label: "Austria",
		alpha2: "AT",
		alpha3: "AUT",
		numeric: 40,
		latitude: 47.3333,
		longitude: 13.3333,
	},
	{
		label: "Azerbaijan",
		alpha2: "AZ",
		alpha3: "AZE",
		numeric: 31,
		latitude: 40.5,
		longitude: 47.5,
	},
	{
		label: "Bahamas",
		alpha2: "BS",
		alpha3: "BHS",
		numeric: 44,
		latitude: 24.25,
		longitude: -76,
	},
	{
		label: "Bahrain",
		alpha2: "BH",
		alpha3: "BHR",
		numeric: 48,
		latitude: 26,
		longitude: 50.55,
	},
	{
		label: "Bangladesh",
		alpha2: "BD",
		alpha3: "BGD",
		numeric: 50,
		latitude: 24,
		longitude: 90,
	},
	{
		label: "Barbados",
		alpha2: "BB",
		alpha3: "BRB",
		numeric: 52,
		latitude: 13.1667,
		longitude: -59.5333,
	},
	{
		label: "Belarus",
		alpha2: "BY",
		alpha3: "BLR",
		numeric: 112,
		latitude: 53,
		longitude: 28,
	},
	{
		label: "Belgium",
		alpha2: "BE",
		alpha3: "BEL",
		numeric: 56,
		latitude: 50.8333,
		longitude: 4,
	},
	{
		label: "Belize",
		alpha2: "BZ",
		alpha3: "BLZ",
		numeric: 84,
		latitude: 17.25,
		longitude: -88.75,
	},
	{
		label: "Benin",
		alpha2: "BJ",
		alpha3: "BEN",
		numeric: 204,
		latitude: 9.5,
		longitude: 2.25,
	},
	{
		label: "Bermuda",
		alpha2: "BM",
		alpha3: "BMU",
		numeric: 60,
		latitude: 32.3333,
		longitude: -64.75,
	},
	{
		label: "Bhutan",
		alpha2: "BT",
		alpha3: "BTN",
		numeric: 64,
		latitude: 27.5,
		longitude: 90.5,
	},
	{
		label: "Bolivia, Plurinational State of",
		alpha2: "BO",
		alpha3: "BOL",
		numeric: 68,
		latitude: -17,
		longitude: -65,
	},
	{
		label: "Bosnia and Herzegovina",
		alpha2: "BA",
		alpha3: "BIH",
		numeric: 70,
		latitude: 44,
		longitude: 18,
	},
	{
		label: "Botswana",
		alpha2: "BW",
		alpha3: "BWA",
		numeric: 72,
		latitude: -22,
		longitude: 24,
	},
	{
		label: "Bouvet Island",
		alpha2: "BV",
		alpha3: "BVT",
		numeric: 74,
		latitude: -54.4333,
		longitude: 3.4,
	},
	{
		label: "Brazil",
		alpha2: "BR",
		alpha3: "BRA",
		numeric: 76,
		latitude: -10,
		longitude: -55,
	},
	{
		label: "British Indian Ocean Territory",
		alpha2: "IO",
		alpha3: "IOT",
		numeric: 86,
		latitude: -6,
		longitude: 71.5,
	},
	{
		label: "Brunei Darussalam",
		alpha2: "BN",
		alpha3: "BRN",
		numeric: 96,
		latitude: 4.5,
		longitude: 114.6667,
	},
	{
		label: "Bulgaria",
		alpha2: "BG",
		alpha3: "BGR",
		numeric: 100,
		latitude: 43,
		longitude: 25,
	},
	{
		label: "Burkina Faso",
		alpha2: "BF",
		alpha3: "BFA",
		numeric: 854,
		latitude: 13,
		longitude: -2,
	},
	{
		label: "Burundi",
		alpha2: "BI",
		alpha3: "BDI",
		numeric: 108,
		latitude: -3.5,
		longitude: 30,
	},
	{
		label: "Cambodia",
		alpha2: "KH",
		alpha3: "KHM",
		numeric: 116,
		latitude: 13,
		longitude: 105,
	},
	{
		label: "Cameroon",
		alpha2: "CM",
		alpha3: "CMR",
		numeric: 120,
		latitude: 6,
		longitude: 12,
	},
	{
		label: "Canada",
		alpha2: "CA",
		alpha3: "CAN",
		numeric: 124,
		latitude: 60,
		longitude: -95,
	},
	{
		label: "Cape Verde",
		alpha2: "CV",
		alpha3: "CPV",
		numeric: 132,
		latitude: 16,
		longitude: -24,
	},
	{
		label: "Cayman Islands",
		alpha2: "KY",
		alpha3: "CYM",
		numeric: 136,
		latitude: 19.5,
		longitude: -80.5,
	},
	{
		label: "Central African Republic",
		alpha2: "CF",
		alpha3: "CAF",
		numeric: 140,
		latitude: 7,
		longitude: 21,
	},
	{
		label: "Chad",
		alpha2: "TD",
		alpha3: "TCD",
		numeric: 148,
		latitude: 15,
		longitude: 19,
	},
	{
		label: "Chile",
		alpha2: "CL",
		alpha3: "CHL",
		numeric: 152,
		latitude: -30,
		longitude: -71,
	},
	{
		label: "China",
		alpha2: "CN",
		alpha3: "CHN",
		numeric: 156,
		latitude: 35,
		longitude: 105,
	},
	{
		label: "Christmas Island",
		alpha2: "CX",
		alpha3: "CXR",
		numeric: 162,
		latitude: -10.5,
		longitude: 105.6667,
	},
	{
		label: "Cocos (Keeling) Islands",
		alpha2: "CC",
		alpha3: "CCK",
		numeric: 166,
		latitude: -12.5,
		longitude: 96.8333,
	},
	{
		label: "Colombia",
		alpha2: "CO",
		alpha3: "COL",
		numeric: 170,
		latitude: 4,
		longitude: -72,
	},
	{
		label: "Comoros",
		alpha2: "KM",
		alpha3: "COM",
		numeric: 174,
		latitude: -12.1667,
		longitude: 44.25,
	},
	{
		label: "Congo",
		alpha2: "CG",
		alpha3: "COG",
		numeric: 178,
		latitude: -1,
		longitude: 15,
	},
	{
		label: "Congo, the Democratic Republic of the",
		alpha2: "CD",
		alpha3: "COD",
		numeric: 180,
		latitude: 0,
		longitude: 25,
	},
	{
		label: "Cook Islands",
		alpha2: "CK",
		alpha3: "COK",
		numeric: 184,
		latitude: -21.2333,
		longitude: -159.7667,
	},
	{
		label: "Costa Rica",
		alpha2: "CR",
		alpha3: "CRI",
		numeric: 188,
		latitude: 10,
		longitude: -84,
	},
	{
		label: "Côte d'Ivoire",
		alpha2: "CI",
		alpha3: "CIV",
		numeric: 384,
		latitude: 8,
		longitude: -5,
	},
	{
		label: "Croatia",
		alpha2: "HR",
		alpha3: "HRV",
		numeric: 191,
		latitude: 45.1667,
		longitude: 15.5,
	},
	{
		label: "Cuba",
		alpha2: "CU",
		alpha3: "CUB",
		numeric: 192,
		latitude: 21.5,
		longitude: -80,
	},
	{
		label: "Cyprus",
		alpha2: "CY",
		alpha3: "CYP",
		numeric: 196,
		latitude: 35,
		longitude: 33,
	},
	{
		label: "Czech Republic",
		alpha2: "CZ",
		alpha3: "CZE",
		numeric: 203,
		latitude: 49.75,
		longitude: 15.5,
	},
	{
		label: "Denmark",
		alpha2: "DK",
		alpha3: "DNK",
		numeric: 208,
		latitude: 56,
		longitude: 10,
	},
	{
		label: "Djibouti",
		alpha2: "DJ",
		alpha3: "DJI",
		numeric: 262,
		latitude: 11.5,
		longitude: 43,
	},
	{
		label: "Dominica",
		alpha2: "DM",
		alpha3: "DMA",
		numeric: 212,
		latitude: 15.4167,
		longitude: -61.3333,
	},
	{
		label: "Dominican Republic",
		alpha2: "DO",
		alpha3: "DOM",
		numeric: 214,
		latitude: 19,
		longitude: -70.6667,
	},
	{
		label: "Ecuador",
		alpha2: "EC",
		alpha3: "ECU",
		numeric: 218,
		latitude: -2,
		longitude: -77.5,
	},
	{
		label: "Egypt",
		alpha2: "EG",
		alpha3: "EGY",
		numeric: 818,
		latitude: 27,
		longitude: 30,
	},
	{
		label: "El Salvador",
		alpha2: "SV",
		alpha3: "SLV",
		numeric: 222,
		latitude: 13.8333,
		longitude: -88.9167,
	},
	{
		label: "Equatorial Guinea",
		alpha2: "GQ",
		alpha3: "GNQ",
		numeric: 226,
		latitude: 2,
		longitude: 10,
	},
	{
		label: "Eritrea",
		alpha2: "ER",
		alpha3: "ERI",
		numeric: 232,
		latitude: 15,
		longitude: 39,
	},
	{
		label: "Estonia",
		alpha2: "EE",
		alpha3: "EST",
		numeric: 233,
		latitude: 59,
		longitude: 26,
	},
	{
		label: "Ethiopia",
		alpha2: "ET",
		alpha3: "ETH",
		numeric: 231,
		latitude: 8,
		longitude: 38,
	},
	{
		label: "Falkland Islands (Malvinas)",
		alpha2: "FK",
		alpha3: "FLK",
		numeric: 238,
		latitude: -51.75,
		longitude: -59,
	},
	{
		label: "Faroe Islands",
		alpha2: "FO",
		alpha3: "FRO",
		numeric: 234,
		latitude: 62,
		longitude: -7,
	},
	{
		label: "Fiji",
		alpha2: "FJ",
		alpha3: "FJI",
		numeric: 242,
		latitude: -18,
		longitude: 175,
	},
	{
		label: "Finland",
		alpha2: "FI",
		alpha3: "FIN",
		numeric: 246,
		latitude: 64,
		longitude: 26,
	},
	{
		label: "France",
		alpha2: "FR",
		alpha3: "FRA",
		numeric: 250,
		latitude: 46,
		longitude: 2,
	},
	{
		label: "French Guiana",
		alpha2: "GF",
		alpha3: "GUF",
		numeric: 254,
		latitude: 4,
		longitude: -53,
	},
	{
		label: "French Polynesia",
		alpha2: "PF",
		alpha3: "PYF",
		numeric: 258,
		latitude: -15,
		longitude: -140,
	},
	{
		label: "French Southern Territories",
		alpha2: "TF",
		alpha3: "ATF",
		numeric: 260,
		latitude: -43,
		longitude: 67,
	},
	{
		label: "Gabon",
		alpha2: "GA",
		alpha3: "GAB",
		numeric: 266,
		latitude: -1,
		longitude: 11.75,
	},
	{
		label: "Gambia",
		alpha2: "GM",
		alpha3: "GMB",
		numeric: 270,
		latitude: 13.4667,
		longitude: -16.5667,
	},
	{
		label: "Georgia",
		alpha2: "GE",
		alpha3: "GEO",
		numeric: 268,
		latitude: 42,
		longitude: 43.5,
	},
	{
		label: "Germany",
		alpha2: "DE",
		alpha3: "DEU",
		numeric: 276,
		latitude: 51,
		longitude: 9,
	},
	{
		label: "Ghana",
		alpha2: "GH",
		alpha3: "GHA",
		numeric: 288,
		latitude: 8,
		longitude: -2,
	},
	{
		label: "Gibraltar",
		alpha2: "GI",
		alpha3: "GIB",
		numeric: 292,
		latitude: 36.1833,
		longitude: -5.3667,
	},
	{
		label: "Greece",
		alpha2: "GR",
		alpha3: "GRC",
		numeric: 300,
		latitude: 39,
		longitude: 22,
	},
	{
		label: "Greenland",
		alpha2: "GL",
		alpha3: "GRL",
		numeric: 304,
		latitude: 72,
		longitude: -40,
	},
	{
		label: "Grenada",
		alpha2: "GD",
		alpha3: "GRD",
		numeric: 308,
		latitude: 12.1167,
		longitude: -61.6667,
	},
	{
		label: "Guadeloupe",
		alpha2: "GP",
		alpha3: "GLP",
		numeric: 312,
		latitude: 16.25,
		longitude: -61.5833,
	},
	{
		label: "Guam",
		alpha2: "GU",
		alpha3: "GUM",
		numeric: 316,
		latitude: 13.4667,
		longitude: 144.7833,
	},
	{
		label: "Guatemala",
		alpha2: "GT",
		alpha3: "GTM",
		numeric: 320,
		latitude: 15.5,
		longitude: -90.25,
	},
	{
		label: "Guernsey",
		alpha2: "GG",
		alpha3: "GGY",
		numeric: 831,
		latitude: 49.5,
		longitude: -2.56,
	},
	{
		label: "Guinea",
		alpha2: "GN",
		alpha3: "GIN",
		numeric: 324,
		latitude: 11,
		longitude: -10,
	},
	{
		label: "Guinea-Bissau",
		alpha2: "GW",
		alpha3: "GNB",
		numeric: 624,
		latitude: 12,
		longitude: -15,
	},
	{
		label: "Guyana",
		alpha2: "GY",
		alpha3: "GUY",
		numeric: 328,
		latitude: 5,
		longitude: -59,
	},
	{
		label: "Haiti",
		alpha2: "HT",
		alpha3: "HTI",
		numeric: 332,
		latitude: 19,
		longitude: -72.4167,
	},
	{
		label: "Heard Island and McDonald Islands",
		alpha2: "HM",
		alpha3: "HMD",
		numeric: 334,
		latitude: -53.1,
		longitude: 72.5167,
	},
	{
		label: "Holy See (Vatican City State)",
		alpha2: "VA",
		alpha3: "VAT",
		numeric: 336,
		latitude: 41.9,
		longitude: 12.45,
	},
	{
		label: "Honduras",
		alpha2: "HN",
		alpha3: "HND",
		numeric: 340,
		latitude: 15,
		longitude: -86.5,
	},
	{
		label: "Hong Kong",
		alpha2: "HK",
		alpha3: "HKG",
		numeric: 344,
		latitude: 22.25,
		longitude: 114.1667,
	},
	{
		label: "Hungary",
		alpha2: "HU",
		alpha3: "HUN",
		numeric: 348,
		latitude: 47,
		longitude: 20,
	},
	{
		label: "Iceland",
		alpha2: "IS",
		alpha3: "ISL",
		numeric: 352,
		latitude: 65,
		longitude: -18,
	},
	{
		label: "India",
		alpha2: "IN",
		alpha3: "IND",
		numeric: 356,
		latitude: 20,
		longitude: 77,
	},
	{
		label: "Indonesia",
		alpha2: "ID",
		alpha3: "IDN",
		numeric: 360,
		latitude: -5,
		longitude: 120,
	},
	{
		label: "Iran, Islamic Republic of",
		alpha2: "IR",
		alpha3: "IRN",
		numeric: 364,
		latitude: 32,
		longitude: 53,
	},
	{
		label: "Iraq",
		alpha2: "IQ",
		alpha3: "IRQ",
		numeric: 368,
		latitude: 33,
		longitude: 44,
	},
	{
		label: "Ireland",
		alpha2: "IE",
		alpha3: "IRL",
		numeric: 372,
		latitude: 53,
		longitude: -8,
	},
	{
		label: "Isle of Man",
		alpha2: "IM",
		alpha3: "IMN",
		numeric: 833,
		latitude: 54.23,
		longitude: -4.55,
	},
	{
		label: "Israel",
		alpha2: "IL",
		alpha3: "ISR",
		numeric: 376,
		latitude: 31.5,
		longitude: 34.75,
	},
	{
		label: "Italy",
		alpha2: "IT",
		alpha3: "ITA",
		numeric: 380,
		latitude: 42.8333,
		longitude: 12.8333,
	},
	{
		label: "Jamaica",
		alpha2: "JM",
		alpha3: "JAM",
		numeric: 388,
		latitude: 18.25,
		longitude: -77.5,
	},
	{
		label: "Japan",
		alpha2: "JP",
		alpha3: "JPN",
		numeric: 392,
		latitude: 36,
		longitude: 138,
	},
	{
		label: "Jersey",
		alpha2: "JE",
		alpha3: "JEY",
		numeric: 832,
		latitude: 49.21,
		longitude: -2.13,
	},
	{
		label: "Jordan",
		alpha2: "JO",
		alpha3: "JOR",
		numeric: 400,
		latitude: 31,
		longitude: 36,
	},
	{
		label: "Kazakhstan",
		alpha2: "KZ",
		alpha3: "KAZ",
		numeric: 398,
		latitude: 48,
		longitude: 68,
	},
	{
		label: "Kenya",
		alpha2: "KE",
		alpha3: "KEN",
		numeric: 404,
		latitude: 1,
		longitude: 38,
	},
	{
		label: "Kiribati",
		alpha2: "KI",
		alpha3: "KIR",
		numeric: 296,
		latitude: 1.4167,
		longitude: 173,
	},
	{
		label: "Korea, Democratic People's Republic of",
		alpha2: "KP",
		alpha3: "PRK",
		numeric: 408,
		latitude: 40,
		longitude: 127,
	},
	{
		label: "Korea, Republic of",
		alpha2: "KR",
		alpha3: "KOR",
		numeric: 410,
		latitude: 37,
		longitude: 127.5,
	},
	{
		label: "Kuwait",
		alpha2: "KW",
		alpha3: "KWT",
		numeric: 414,
		latitude: 29.3375,
		longitude: 47.6581,
	},
	{
		label: "Kyrgyzstan",
		alpha2: "KG",
		alpha3: "KGZ",
		numeric: 417,
		latitude: 41,
		longitude: 75,
	},
	{
		label: "Lao People's Democratic Republic",
		alpha2: "LA",
		alpha3: "LAO",
		numeric: 418,
		latitude: 18,
		longitude: 105,
	},
	{
		label: "Latvia",
		alpha2: "LV",
		alpha3: "LVA",
		numeric: 428,
		latitude: 57,
		longitude: 25,
	},
	{
		label: "Lebanon",
		alpha2: "LB",
		alpha3: "LBN",
		numeric: 422,
		latitude: 33.8333,
		longitude: 35.8333,
	},
	{
		label: "Lesotho",
		alpha2: "LS",
		alpha3: "LSO",
		numeric: 426,
		latitude: -29.5,
		longitude: 28.5,
	},
	{
		label: "Liberia",
		alpha2: "LR",
		alpha3: "LBR",
		numeric: 430,
		latitude: 6.5,
		longitude: -9.5,
	},
	{
		label: "Libyan Arab Jamahiriya",
		alpha2: "LY",
		alpha3: "LBY",
		numeric: 434,
		latitude: 25,
		longitude: 17,
	},
	{
		label: "Liechtenstein",
		alpha2: "LI",
		alpha3: "LIE",
		numeric: 438,
		latitude: 47.1667,
		longitude: 9.5333,
	},
	{
		label: "Lithuania",
		alpha2: "LT",
		alpha3: "LTU",
		numeric: 440,
		latitude: 56,
		longitude: 24,
	},
	{
		label: "Luxembourg",
		alpha2: "LU",
		alpha3: "LUX",
		numeric: 442,
		latitude: 49.75,
		longitude: 6.1667,
	},
	{
		label: "Macao",
		alpha2: "MO",
		alpha3: "MAC",
		numeric: 446,
		latitude: 22.1667,
		longitude: 113.55,
	},
	{
		label: "Macedonia, the former Yugoslav Republic of",
		alpha2: "MK",
		alpha3: "MKD",
		numeric: 807,
		latitude: 41.8333,
		longitude: 22,
	},
	{
		label: "Madagascar",
		alpha2: "MG",
		alpha3: "MDG",
		numeric: 450,
		latitude: -20,
		longitude: 47,
	},
	{
		label: "Malawi",
		alpha2: "MW",
		alpha3: "MWI",
		numeric: 454,
		latitude: -13.5,
		longitude: 34,
	},
	{
		label: "Malaysia",
		alpha2: "MY",
		alpha3: "MYS",
		numeric: 458,
		latitude: 2.5,
		longitude: 112.5,
	},
	{
		label: "Maldives",
		alpha2: "MV",
		alpha3: "MDV",
		numeric: 462,
		latitude: 3.25,
		longitude: 73,
	},
	{
		label: "Mali",
		alpha2: "ML",
		alpha3: "MLI",
		numeric: 466,
		latitude: 17,
		longitude: -4,
	},
	{
		label: "Malta",
		alpha2: "MT",
		alpha3: "MLT",
		numeric: 470,
		latitude: 35.8333,
		longitude: 14.5833,
	},
	{
		label: "Marshall Islands",
		alpha2: "MH",
		alpha3: "MHL",
		numeric: 584,
		latitude: 9,
		longitude: 168,
	},
	{
		label: "Martinique",
		alpha2: "MQ",
		alpha3: "MTQ",
		numeric: 474,
		latitude: 14.6667,
		longitude: -61,
	},
	{
		label: "Mauritania",
		alpha2: "MR",
		alpha3: "MRT",
		numeric: 478,
		latitude: 20,
		longitude: -12,
	},
	{
		label: "Mauritius",
		alpha2: "MU",
		alpha3: "MUS",
		numeric: 480,
		latitude: -20.2833,
		longitude: 57.55,
	},
	{
		label: "Mayotte",
		alpha2: "YT",
		alpha3: "MYT",
		numeric: 175,
		latitude: -12.8333,
		longitude: 45.1667,
	},
	{
		label: "Mexico",
		alpha2: "MX",
		alpha3: "MEX",
		numeric: 484,
		latitude: 23,
		longitude: -102,
	},
	{
		label: "Micronesia, Federated States of",
		alpha2: "FM",
		alpha3: "FSM",
		numeric: 583,
		latitude: 6.9167,
		longitude: 158.25,
	},
	{
		label: "Moldova, Republic of",
		alpha2: "MD",
		alpha3: "MDA",
		numeric: 498,
		latitude: 47,
		longitude: 29,
	},
	{
		label: "Monaco",
		alpha2: "MC",
		alpha3: "MCO",
		numeric: 492,
		latitude: 43.7333,
		longitude: 7.4,
	},
	{
		label: "Mongolia",
		alpha2: "MN",
		alpha3: "MNG",
		numeric: 496,
		latitude: 46,
		longitude: 105,
	},
	{
		label: "Montenegro",
		alpha2: "ME",
		alpha3: "MNE",
		numeric: 499,
		latitude: 42,
		longitude: 19,
	},
	{
		label: "Montserrat",
		alpha2: "MS",
		alpha3: "MSR",
		numeric: 500,
		latitude: 16.75,
		longitude: -62.2,
	},
	{
		label: "Morocco",
		alpha2: "MA",
		alpha3: "MAR",
		numeric: 504,
		latitude: 32,
		longitude: -5,
	},
	{
		label: "Mozambique",
		alpha2: "MZ",
		alpha3: "MOZ",
		numeric: 508,
		latitude: -18.25,
		longitude: 35,
	},
	{
		label: "Myanmar",
		alpha2: "MM",
		alpha3: "MMR",
		numeric: 104,
		latitude: 22,
		longitude: 98,
	},
	{
		label: "Namibia",
		alpha2: "NA",
		alpha3: "NAM",
		numeric: 516,
		latitude: -22,
		longitude: 17,
	},
	{
		label: "Nauru",
		alpha2: "NR",
		alpha3: "NRU",
		numeric: 520,
		latitude: -0.5333,
		longitude: 166.9167,
	},
	{
		label: "Nepal",
		alpha2: "NP",
		alpha3: "NPL",
		numeric: 524,
		latitude: 28,
		longitude: 84,
	},
	{
		label: "Netherlands",
		alpha2: "NL",
		alpha3: "NLD",
		numeric: 528,
		latitude: 52.5,
		longitude: 5.75,
	},
	{
		label: "Netherlands Antilles",
		alpha2: "AN",
		alpha3: "ANT",
		numeric: 530,
		latitude: 12.25,
		longitude: -68.75,
	},
	{
		label: "New Caledonia",
		alpha2: "NC",
		alpha3: "NCL",
		numeric: 540,
		latitude: -21.5,
		longitude: 165.5,
	},
	{
		label: "New Zealand",
		alpha2: "NZ",
		alpha3: "NZL",
		numeric: 554,
		latitude: -41,
		longitude: 174,
	},
	{
		label: "Nicaragua",
		alpha2: "NI",
		alpha3: "NIC",
		numeric: 558,
		latitude: 13,
		longitude: -85,
	},
	{
		label: "Niger",
		alpha2: "NE",
		alpha3: "NER",
		numeric: 562,
		latitude: 16,
		longitude: 8,
	},
	{
		label: "Nigeria",
		alpha2: "NG",
		alpha3: "NGA",
		numeric: 566,
		latitude: 10,
		longitude: 8,
	},
	{
		label: "Niue",
		alpha2: "NU",
		alpha3: "NIU",
		numeric: 570,
		latitude: -19.0333,
		longitude: -169.8667,
	},
	{
		label: "Norfolk Island",
		alpha2: "NF",
		alpha3: "NFK",
		numeric: 574,
		latitude: -29.0333,
		longitude: 167.95,
	},
	{
		label: "Northern Mariana Islands",
		alpha2: "MP",
		alpha3: "MNP",
		numeric: 580,
		latitude: 15.2,
		longitude: 145.75,
	},
	{
		label: "Norway",
		alpha2: "NO",
		alpha3: "NOR",
		numeric: 578,
		latitude: 62,
		longitude: 10,
	},
	{
		label: "Oman",
		alpha2: "OM",
		alpha3: "OMN",
		numeric: 512,
		latitude: 21,
		longitude: 57,
	},
	{
		label: "Pakistan",
		alpha2: "PK",
		alpha3: "PAK",
		numeric: 586,
		latitude: 30,
		longitude: 70,
	},
	{
		label: "Palau",
		alpha2: "PW",
		alpha3: "PLW",
		numeric: 585,
		latitude: 7.5,
		longitude: 134.5,
	},
	{
		label: "Palestinian Territory, Occupied",
		alpha2: "PS",
		alpha3: "PSE",
		numeric: 275,
		latitude: 32,
		longitude: 35.25,
	},
	{
		label: "Panama",
		alpha2: "PA",
		alpha3: "PAN",
		numeric: 591,
		latitude: 9,
		longitude: -80,
	},
	{
		label: "Papua New Guinea",
		alpha2: "PG",
		alpha3: "PNG",
		numeric: 598,
		latitude: -6,
		longitude: 147,
	},
	{
		label: "Paraguay",
		alpha2: "PY",
		alpha3: "PRY",
		numeric: 600,
		latitude: -23,
		longitude: -58,
	},
	{
		label: "Peru",
		alpha2: "PE",
		alpha3: "PER",
		numeric: 604,
		latitude: -10,
		longitude: -76,
	},
	{
		label: "Philippines",
		alpha2: "PH",
		alpha3: "PHL",
		numeric: 608,
		latitude: 13,
		longitude: 122,
	},
	{
		label: "Pitcairn",
		alpha2: "PN",
		alpha3: "PCN",
		numeric: 612,
		latitude: -24.7,
		longitude: -127.4,
	},
	{
		label: "Poland",
		alpha2: "PL",
		alpha3: "POL",
		numeric: 616,
		latitude: 52,
		longitude: 20,
	},
	{
		label: "Portugal",
		alpha2: "PT",
		alpha3: "PRT",
		numeric: 620,
		latitude: 39.5,
		longitude: -8,
	},
	{
		label: "Puerto Rico",
		alpha2: "PR",
		alpha3: "PRI",
		numeric: 630,
		latitude: 18.25,
		longitude: -66.5,
	},
	{
		label: "Qatar",
		alpha2: "QA",
		alpha3: "QAT",
		numeric: 634,
		latitude: 25.5,
		longitude: 51.25,
	},
	{
		label: "Réunion",
		alpha2: "RE",
		alpha3: "REU",
		numeric: 638,
		latitude: -21.1,
		longitude: 55.6,
	},
	{
		label: "Romania",
		alpha2: "RO",
		alpha3: "ROU",
		numeric: 642,
		latitude: 46,
		longitude: 25,
	},
	{
		label: "Russian Federation",
		alpha2: "RU",
		alpha3: "RUS",
		numeric: 643,
		latitude: 60,
		longitude: 100,
	},
	{
		label: "Rwanda",
		alpha2: "RW",
		alpha3: "RWA",
		numeric: 646,
		latitude: -2,
		longitude: 30,
	},
	{
		label: "Saint Helena, Ascension and Tristan da Cunha",
		alpha2: "SH",
		alpha3: "SHN",
		numeric: 654,
		latitude: -15.9333,
		longitude: -5.7,
	},
	{
		label: "Saint Kitts and Nevis",
		alpha2: "KN",
		alpha3: "KNA",
		numeric: 659,
		latitude: 17.3333,
		longitude: -62.75,
	},
	{
		label: "Saint Lucia",
		alpha2: "LC",
		alpha3: "LCA",
		numeric: 662,
		latitude: 13.8833,
		longitude: -61.1333,
	},
	{
		label: "Saint Pierre and Miquelon",
		alpha2: "PM",
		alpha3: "SPM",
		numeric: 666,
		latitude: 46.8333,
		longitude: -56.3333,
	},
	{
		label: "Saint Vincent and the Grenadines",
		alpha2: "VC",
		alpha3: "VCT",
		numeric: 670,
		latitude: 13.25,
		longitude: -61.2,
	},
	{
		label: "Samoa",
		alpha2: "WS",
		alpha3: "WSM",
		numeric: 882,
		latitude: -13.5833,
		longitude: -172.3333,
	},
	{
		label: "San Marino",
		alpha2: "SM",
		alpha3: "SMR",
		numeric: 674,
		latitude: 43.7667,
		longitude: 12.4167,
	},
	{
		label: "Sao Tome and Principe",
		alpha2: "ST",
		alpha3: "STP",
		numeric: 678,
		latitude: 1,
		longitude: 7,
	},
	{
		label: "Saudi Arabia",
		alpha2: "SA",
		alpha3: "SAU",
		numeric: 682,
		latitude: 25,
		longitude: 45,
	},
	{
		label: "Senegal",
		alpha2: "SN",
		alpha3: "SEN",
		numeric: 686,
		latitude: 14,
		longitude: -14,
	},
	{
		label: "Serbia",
		alpha2: "RS",
		alpha3: "SRB",
		numeric: 688,
		latitude: 44,
		longitude: 21,
	},
	{
		label: "Seychelles",
		alpha2: "SC",
		alpha3: "SYC",
		numeric: 690,
		latitude: -4.5833,
		longitude: 55.6667,
	},
	{
		label: "Sierra Leone",
		alpha2: "SL",
		alpha3: "SLE",
		numeric: 694,
		latitude: 8.5,
		longitude: -11.5,
	},
	{
		label: "Singapore",
		alpha2: "SG",
		alpha3: "SGP",
		numeric: 702,
		latitude: 1.3667,
		longitude: 103.8,
	},
	{
		label: "Slovakia",
		alpha2: "SK",
		alpha3: "SVK",
		numeric: 703,
		latitude: 48.6667,
		longitude: 19.5,
	},
	{
		label: "Slovenia",
		alpha2: "SI",
		alpha3: "SVN",
		numeric: 705,
		latitude: 46,
		longitude: 15,
	},
	{
		label: "Solomon Islands",
		alpha2: "SB",
		alpha3: "SLB",
		numeric: 90,
		latitude: -8,
		longitude: 159,
	},
	{
		label: "Somalia",
		alpha2: "SO",
		alpha3: "SOM",
		numeric: 706,
		latitude: 10,
		longitude: 49,
	},
	{
		label: "South Africa",
		alpha2: "ZA",
		alpha3: "ZAF",
		numeric: 710,
		latitude: -29,
		longitude: 24,
	},
	{
		label: "South Georgia and the South Sandwich Islands",
		alpha2: "GS",
		alpha3: "SGS",
		numeric: 239,
		latitude: -54.5,
		longitude: -37,
	},
	{
		label: "Spain",
		alpha2: "ES",
		alpha3: "ESP",
		numeric: 724,
		latitude: 40,
		longitude: -4,
	},
	{
		label: "Sri Lanka",
		alpha2: "LK",
		alpha3: "LKA",
		numeric: 144,
		latitude: 7,
		longitude: 81,
	},
	{
		label: "Sudan",
		alpha2: "SD",
		alpha3: "SDN",
		numeric: 736,
		latitude: 15,
		longitude: 30,
	},
	{
		label: "Suriname",
		alpha2: "SR",
		alpha3: "SUR",
		numeric: 740,
		latitude: 4,
		longitude: -56,
	},
	{
		label: "Svalbard and Jan Mayen",
		alpha2: "SJ",
		alpha3: "SJM",
		numeric: 744,
		latitude: 78,
		longitude: 20,
	},
	{
		label: "Swaziland",
		alpha2: "SZ",
		alpha3: "SWZ",
		numeric: 748,
		latitude: -26.5,
		longitude: 31.5,
	},
	{
		label: "Sweden",
		alpha2: "SE",
		alpha3: "SWE",
		numeric: 752,
		latitude: 62,
		longitude: 15,
	},
	{
		label: "Switzerland",
		alpha2: "CH",
		alpha3: "CHE",
		numeric: 756,
		latitude: 47,
		longitude: 8,
	},
	{
		label: "Syrian Arab Republic",
		alpha2: "SY",
		alpha3: "SYR",
		numeric: 760,
		latitude: 35,
		longitude: 38,
	},
	{
		label: "Taiwan, Province of China",
		alpha2: "TW",
		alpha3: "TWN",
		numeric: 158,
		latitude: 23.5,
		longitude: 121,
	},
	{
		label: "Tajikistan",
		alpha2: "TJ",
		alpha3: "TJK",
		numeric: 762,
		latitude: 39,
		longitude: 71,
	},
	{
		label: "Tanzania, United Republic of",
		alpha2: "TZ",
		alpha3: "TZA",
		numeric: 834,
		latitude: -6,
		longitude: 35,
	},
	{
		label: "Thailand",
		alpha2: "TH",
		alpha3: "THA",
		numeric: 764,
		latitude: 15,
		longitude: 100,
	},
	{
		label: "Timor-Leste",
		alpha2: "TL",
		alpha3: "TLS",
		numeric: 626,
		latitude: -8.55,
		longitude: 125.5167,
	},
	{
		label: "Togo",
		alpha2: "TG",
		alpha3: "TGO",
		numeric: 768,
		latitude: 8,
		longitude: 1.1667,
	},
	{
		label: "Tokelau",
		alpha2: "TK",
		alpha3: "TKL",
		numeric: 772,
		latitude: -9,
		longitude: -172,
	},
	{
		label: "Tonga",
		alpha2: "TO",
		alpha3: "TON",
		numeric: 776,
		latitude: -20,
		longitude: -175,
	},
	{
		label: "Trinidad and Tobago",
		alpha2: "TT",
		alpha3: "TTO",
		numeric: 780,
		latitude: 11,
		longitude: -61,
	},
	{
		label: "Tunisia",
		alpha2: "TN",
		alpha3: "TUN",
		numeric: 788,
		latitude: 34,
		longitude: 9,
	},
	{
		label: "Turkey",
		alpha2: "TR",
		alpha3: "TUR",
		numeric: 792,
		latitude: 39,
		longitude: 35,
	},
	{
		label: "Turkmenistan",
		alpha2: "TM",
		alpha3: "TKM",
		numeric: 795,
		latitude: 40,
		longitude: 60,
	},
	{
		label: "Turks and Caicos Islands",
		alpha2: "TC",
		alpha3: "TCA",
		numeric: 796,
		latitude: 21.75,
		longitude: -71.5833,
	},
	{
		label: "Tuvalu",
		alpha2: "TV",
		alpha3: "TUV",
		numeric: 798,
		latitude: -8,
		longitude: 178,
	},
	{
		label: "Uganda",
		alpha2: "UG",
		alpha3: "UGA",
		numeric: 800,
		latitude: 1,
		longitude: 32,
	},
	{
		label: "Ukraine",
		alpha2: "UA",
		alpha3: "UKR",
		numeric: 804,
		latitude: 49,
		longitude: 32,
	},
	{
		label: "United Arab Emirates",
		alpha2: "AE",
		alpha3: "ARE",
		numeric: 784,
		latitude: 24,
		longitude: 54,
	},
	{
		label: "United Kingdom",
		alpha2: "GB",
		alpha3: "GBR",
		numeric: 826,
		latitude: 54,
		longitude: -2,
	},
	{
		label: "United States",
		alpha2: "US",
		alpha3: "USA",
		numeric: 840,
		latitude: 38,
		longitude: -97,
	},
	{
		label: "United States Minor Outlying Islands",
		alpha2: "UM",
		alpha3: "UMI",
		numeric: 581,
		latitude: 19.2833,
		longitude: 166.6,
	},
	{
		label: "Uruguay",
		alpha2: "UY",
		alpha3: "URY",
		numeric: 858,
		latitude: -33,
		longitude: -56,
	},
	{
		label: "Uzbekistan",
		alpha2: "UZ",
		alpha3: "UZB",
		numeric: 860,
		latitude: 41,
		longitude: 64,
	},
	{
		label: "Vanuatu",
		alpha2: "VU",
		alpha3: "VUT",
		numeric: 548,
		latitude: -16,
		longitude: 167,
	},
	{
		label: "Venezuela, Bolivarian Republic of",
		alpha2: "VE",
		alpha3: "VEN",
		numeric: 862,
		latitude: 8,
		longitude: -66,
	},
	{
		label: "Viet Nam",
		alpha2: "VN",
		alpha3: "VNM",
		numeric: 704,
		latitude: 16,
		longitude: 106,
	},
	{
		label: "Virgin Islands, British",
		alpha2: "VG",
		alpha3: "VGB",
		numeric: 92,
		latitude: 18.5,
		longitude: -64.5,
	},
	{
		label: "Virgin Islands, U.S.",
		alpha2: "VI",
		alpha3: "VIR",
		numeric: 850,
		latitude: 18.3333,
		longitude: -64.8333,
	},
	{
		label: "Wallis and Futuna",
		alpha2: "WF",
		alpha3: "WLF",
		numeric: 876,
		latitude: -13.3,
		longitude: -176.2,
	},
	{
		label: "Western Sahara",
		alpha2: "EH",
		alpha3: "ESH",
		numeric: 732,
		latitude: 24.5,
		longitude: -13,
	},
	{
		label: "Yemen",
		alpha2: "YE",
		alpha3: "YEM",
		numeric: 887,
		latitude: 15,
		longitude: 48,
	},
	{
		label: "Zambia",
		alpha2: "ZM",
		alpha3: "ZMB",
		numeric: 894,
		latitude: -15,
		longitude: 30,
	},
	{
		label: "Zimbabwe",
		alpha2: "ZW",
		alpha3: "ZWE",
		numeric: 716,
		latitude: -20,
		longitude: 30,
	},
	{
		label: "Afghanistan",
		alpha2: "AF",
		alpha3: "AFG",
		numeric: 4,
		latitude: 33,
		longitude: 65,
	},
];

type boundaryType = {
  [index: string]: box
}
type box = {
	sw: any ,
	ne: any,
}

export const boundaries: boundaryType = {
	ABW: {
		sw: {lat: 12.1702998, lng: -70.2809842},
		ne: {lat: 12.8102998, lng: -69.6409842},
	},
	AFG: {
		sw: {lat: 29.3772, lng: 60.5176034},
		ne: {lat: 38.4910682, lng: 74.889862},
	},
	AGO: {
		sw: {lat: -18.038945, lng: 11.4609793},
		ne: {lat: -4.3880634, lng: 24.0878856},
	},
	AIA: {
		sw: {lat: 18.0615454, lng: -63.6391992},
		ne: {lat: 18.7951194, lng: -62.7125449},
	},
	ALA: {
		sw: {lat: 59.4541578, lng: 19.0832098},
		ne: {lat: 60.87665, lng: 21.3456556},
	},
	ALB: {
		sw: {lat: 39.6448625, lng: 19.1246095},
		ne: {lat: 42.6610848, lng: 21.0574335},
	},
	AND: {
		sw: {lat: 42.4288238, lng: 1.4135781},
		ne: {lat: 42.6559357, lng: 1.7863837},
	},
	ARE: {sw: {lat: 22.6444, lng: 51.498}, ne: {lat: 26.2822, lng: 56.3834}},
	ARG: {
		sw: {lat: -55.1850761, lng: -73.5600329},
		ne: {lat: -21.781168, lng: -53.6374515},
	},
	ARM: {
		sw: {lat: 38.8404775, lng: 43.4471395},
		ne: {lat: 41.300712, lng: 46.6333087},
	},
	ASM: {
		sw: {lat: -14.7608358, lng: -171.2951296},
		ne: {lat: -10.8449746, lng: -167.9322899},
	},
	ATA: {sw: {lat: -85.0511287, lng: -180.0}, ne: {lat: -60.0, lng: 180.0}},
	ATF: {
		sw: {lat: -50.2187169, lng: 39.4138676},
		ne: {lat: -11.3139928, lng: 77.8494974},
	},
	ATG: {
		sw: {lat: 16.7573901, lng: -62.5536517},
		ne: {lat: 17.929, lng: -61.447857},
	},
	AUS: {
		sw: {lat: -55.3228175, lng: 72.2460938},
		ne: {lat: -9.0882278, lng: 168.2249543},
	},
	AUT: {
		sw: {lat: 46.3722761, lng: 9.5307487},
		ne: {lat: 49.0205305, lng: 17.160776},
	},
	AZE: {
		sw: {lat: 38.3929551, lng: 44.7633701},
		ne: {lat: 41.9502947, lng: 51.0090302},
	},
	BDI: {
		sw: {lat: -4.4693155, lng: 29.0007401},
		ne: {lat: -2.3096796, lng: 30.8498462},
	},
	BEL: {
		sw: {lat: 49.4969821, lng: 2.3889137},
		ne: {lat: 51.5516667, lng: 6.408097},
	},
	BEN: {
		sw: {lat: 6.0398696, lng: 0.776667},
		ne: {lat: 12.4092447, lng: 3.843343},
	},
	BFA: {
		sw: {lat: 9.4104718, lng: -5.5132416},
		ne: {lat: 15.084, lng: 2.4089717},
	},
	BGD: {
		sw: {lat: 20.3756582, lng: 88.0075306},
		ne: {lat: 26.6382534, lng: 92.6804979},
	},
	BGR: {
		sw: {lat: 41.2353929, lng: 22.3571459},
		ne: {lat: 44.2167064, lng: 28.8875409},
	},
	BHR: {
		sw: {lat: 25.535, lng: 50.2697989},
		ne: {lat: 26.6872444, lng: 50.9233693},
	},
	BHS: {
		sw: {lat: 20.7059846, lng: -80.7001941},
		ne: {lat: 27.4734551, lng: -72.4477521},
	},
	BIH: {
		sw: {lat: 42.5553114, lng: 15.7287433},
		ne: {lat: 45.2764135, lng: 19.6237311},
	},
	BLM: {
		sw: {lat: 17.670931, lng: -63.06639},
		ne: {lat: 18.1375569, lng: -62.5844019},
	},
	BLR: {
		sw: {lat: 51.2575982, lng: 23.1783344},
		ne: {lat: 56.17218, lng: 32.7627809},
	},
	BLZ: {
		sw: {lat: 15.8857286, lng: -89.2262083},
		ne: {lat: 18.496001, lng: -87.3098494},
	},
	BMU: {
		sw: {lat: 32.0469651, lng: -65.1232222},
		ne: {lat: 32.5913693, lng: -64.4109842},
	},
	BOL: {
		sw: {lat: -22.8982742, lng: -69.6450073},
		ne: {lat: -9.6689438, lng: -57.453},
	},
	BRA: {
		sw: {lat: -33.8689056, lng: -73.9830625},
		ne: {lat: 5.2842873, lng: -28.6341164},
	},
	BRB: {
		sw: {lat: 12.845, lng: -59.8562115},
		ne: {lat: 13.535, lng: -59.2147175},
	},
	BRN: {
		sw: {lat: 4.002508, lng: 114.0758734},
		ne: {lat: 5.1011857, lng: 115.3635623},
	},
	BTN: {
		sw: {lat: 26.702016, lng: 88.7464724},
		ne: {lat: 28.246987, lng: 92.1252321},
	},
	BVT: {sw: {lat: -54.654, lng: 2.9345531}, ne: {lat: -54.187, lng: 3.7791099}},
	BWA: {
		sw: {lat: -26.9059669, lng: 19.9986474},
		ne: {lat: -17.778137, lng: 29.375304},
	},
	CAF: {
		sw: {lat: 2.2156553, lng: 14.4155426},
		ne: {lat: 11.001389, lng: 27.4540764},
	},
	CAN: {
		sw: {lat: 41.6765556, lng: -141.00275},
		ne: {lat: 83.3362128, lng: -52.3231981},
	},
	CCK: {
		sw: {lat: -12.4055983, lng: 96.612524},
		ne: {lat: -11.6213132, lng: 97.1357343},
	},
	CHE: {
		sw: {lat: 45.817995, lng: 5.9559113},
		ne: {lat: 47.8084648, lng: 10.4922941},
	},
	CHL: {
		sw: {lat: -56.725, lng: -109.6795789},
		ne: {lat: -17.4983998, lng: -66.0753474},
	},
	CHN: {
		sw: {lat: 8.8383436, lng: 73.4997347},
		ne: {lat: 53.5608154, lng: 134.7754563},
	},
	CIV: {
		sw: {lat: 4.1621205, lng: -8.601725},
		ne: {lat: 10.740197, lng: -2.493031},
	},
	CMR: {
		sw: {lat: 1.6546659, lng: 8.3822176},
		ne: {lat: 13.083333, lng: 16.1921476},
	},
	COD: {
		sw: {lat: -13.459035, lng: 12.039074},
		ne: {lat: 5.3920026, lng: 31.3056758},
	},
	COG: {
		sw: {lat: -5.149089, lng: 11.0048205},
		ne: {lat: 3.713056, lng: 18.643611},
	},
	COK: {
		sw: {lat: -22.15807, lng: -166.0856468},
		ne: {lat: -8.7168792, lng: -157.1089329},
	},
	COL: {
		sw: {lat: -4.2316872, lng: -82.1243666},
		ne: {lat: 16.0571269, lng: -66.8511907},
	},
	COM: {
		sw: {lat: -12.621, lng: 43.025305},
		ne: {lat: -11.165, lng: 44.7451922},
	},
	CPV: {
		sw: {lat: 14.8031546, lng: -25.3609478},
		ne: {lat: 17.2053108, lng: -22.6673416},
	},
	CRI: {
		sw: {lat: 5.3329698, lng: -87.2722647},
		ne: {lat: 11.2195684, lng: -82.5060208},
	},
	CUB: {
		sw: {lat: 19.6275294, lng: -85.1679702},
		ne: {lat: 23.4816972, lng: -73.9190004},
	},
	CXR: {
		sw: {lat: -10.5698515, lng: 105.5336422},
		ne: {lat: -10.4123553, lng: 105.7130159},
	},
	CYM: {
		sw: {lat: 19.0620619, lng: -81.6313748},
		ne: {lat: 19.9573759, lng: -79.5110954},
	},
	CYP: {
		sw: {lat: 34.4383706, lng: 32.0227581},
		ne: {lat: 35.913252, lng: 34.8553182},
	},
	CZE: {
		sw: {lat: 48.5518083, lng: 12.0905901},
		ne: {lat: 51.0557036, lng: 18.859216},
	},
	DEU: {
		sw: {lat: 47.2701114, lng: 5.8663153},
		ne: {lat: 55.099161, lng: 15.0419319},
	},
	DJI: {
		sw: {lat: 10.9149547, lng: 41.7713139},
		ne: {lat: 12.7923081, lng: 43.6579046},
	},
	DMA: {
		sw: {lat: 15.0074207, lng: -61.6869184},
		ne: {lat: 15.7872222, lng: -61.0329895},
	},
	DNK: {
		sw: {lat: 54.4516667, lng: 7.7153255},
		ne: {lat: 57.9524297, lng: 15.5530641},
	},
	DOM: {
		sw: {lat: 17.2701708, lng: -72.0574706},
		ne: {lat: 21.303433, lng: -68.1101463},
	},
	DZA: {
		sw: {lat: 18.968147, lng: -8.668908},
		ne: {lat: 37.2962055, lng: 11.997337},
	},
	ECU: {
		sw: {lat: -5.0159314, lng: -92.2072392},
		ne: {lat: 1.8835964, lng: -75.192504},
	},
	EGY: {
		sw: {lat: 22.0, lng: 24.6499112},
		ne: {lat: 31.8330854, lng: 37.1153517},
	},
	ERI: {
		sw: {lat: 12.3548219, lng: 36.4333653},
		ne: {lat: 18.0709917, lng: 43.3001714},
	},
	ESH: {
		sw: {lat: 20.556883, lng: -17.3494721},
		ne: {lat: 27.6666834, lng: -8.666389},
	},
	ESP: {
		sw: {lat: 27.4335426, lng: -18.3936845},
		ne: {lat: 43.9933088, lng: 4.5918885},
	},
	EST: {
		sw: {lat: 57.5092997, lng: 21.3826069},
		ne: {lat: 59.9383754, lng: 28.2100175},
	},
	ETH: {
		sw: {lat: 3.397448, lng: 32.9975838},
		ne: {lat: 14.8940537, lng: 47.9823797},
	},
	FIN: {
		sw: {lat: 59.4541578, lng: 19.0832098},
		ne: {lat: 70.0922939, lng: 31.5867071},
	},
	FJI: {
		sw: {lat: -21.9434274, lng: 172.0},
		ne: {lat: -12.2613866, lng: -178.5},
	},
	FLK: {
		sw: {lat: -53.1186766, lng: -61.7726772},
		ne: {lat: -50.7973007, lng: -57.3662367},
	},
	FRA: {
		sw: {lat: 41.2632185, lng: -5.4534286},
		ne: {lat: 51.268318, lng: 9.8678344},
	},
	FRO: {
		sw: {lat: 61.3915553, lng: -7.6882939},
		ne: {lat: 62.3942991, lng: -6.2565525},
	},
	FSM: {
		sw: {lat: 0.827, lng: 137.2234512},
		ne: {lat: 10.291, lng: 163.2364054},
	},
	GAB: {
		sw: {lat: -4.1012261, lng: 8.5002246},
		ne: {lat: 2.3182171, lng: 14.539444},
	},
	GBR: {sw: {lat: 49.674, lng: -14.015517}, ne: {lat: 61.061, lng: 2.0919117}},
	GEO: {
		sw: {lat: 41.0552922, lng: 39.8844803},
		ne: {lat: 43.5864294, lng: 46.7365373},
	},
	GGY: {
		sw: {lat: 49.4155331, lng: -2.6751703},
		ne: {lat: 49.5090776, lng: -2.501814},
	},
	GHA: {
		sw: {lat: 4.5392525, lng: -3.260786},
		ne: {lat: 11.1748562, lng: 1.2732942},
	},
	GIB: {
		sw: {lat: 36.100807, lng: -5.3941295},
		ne: {lat: 36.180807, lng: -5.3141295},
	},
	GIN: {
		sw: {lat: 7.1906045, lng: -15.5680508},
		ne: {lat: 12.67563, lng: -7.6381993},
	},
	GLP: {
		sw: {lat: 15.8320085, lng: -61.809764},
		ne: {lat: 16.5144664, lng: -61.0003663},
	},
	GMB: {
		sw: {lat: 13.061, lng: -17.0288254},
		ne: {lat: 13.8253137, lng: -13.797778},
	},
	GNB: {
		sw: {lat: 10.6514215, lng: -16.894523},
		ne: {lat: 12.6862384, lng: -13.6348777},
	},
	GNQ: {
		sw: {lat: -1.6732196, lng: 5.4172943},
		ne: {lat: 3.989, lng: 11.3598628},
	},
	GRC: {
		sw: {lat: 34.7006096, lng: 19.2477876},
		ne: {lat: 41.7488862, lng: 29.7296986},
	},
	GRD: {
		sw: {lat: 11.786, lng: -62.0065868},
		ne: {lat: 12.5966532, lng: -61.1732143},
	},
	GRL: {
		sw: {lat: 59.515387, lng: -74.1250416},
		ne: {lat: 83.875172, lng: -10.0288759},
	},
	GTM: {
		sw: {lat: 13.6345804, lng: -92.3105242},
		ne: {lat: 17.8165947, lng: -88.1755849},
	},
	GUF: {
		sw: {lat: 2.112222, lng: -54.60278},
		ne: {lat: 5.7507111, lng: -51.6346139},
	},
	GUM: {
		sw: {lat: 13.182335, lng: 144.563426},
		ne: {lat: 13.706179, lng: 145.009167},
	},
	GUY: {
		sw: {lat: 1.1710017, lng: -61.414905},
		ne: {lat: 8.6038842, lng: -56.4689543},
	},
	HKG: {
		sw: {lat: 22.1193278, lng: 114.0028131},
		ne: {lat: 22.4393278, lng: 114.3228131},
	},
	HMD: {
		sw: {lat: -53.394741, lng: 72.2460938},
		ne: {lat: -52.7030677, lng: 74.1988754},
	},
	HND: {
		sw: {lat: 12.9808485, lng: -89.3568207},
		ne: {lat: 17.619526, lng: -82.1729621},
	},
	HRV: {
		sw: {lat: 42.1765993, lng: 13.2104814},
		ne: {lat: 46.555029, lng: 19.4470842},
	},
	HTI: {
		sw: {lat: 17.9099291, lng: -75.2384618},
		ne: {lat: 20.2181368, lng: -71.6217461},
	},
	HUN: {
		sw: {lat: 45.737128, lng: 16.1138867},
		ne: {lat: 48.585257, lng: 22.8977094},
	},
	IDN: {
		sw: {lat: -11.2085669, lng: 94.7717124},
		ne: {lat: 6.2744496, lng: 141.0194444},
	},
	IMN: {
		sw: {lat: 54.0539576, lng: -4.7946845},
		ne: {lat: 54.4178705, lng: -4.3076853},
	},
	IND: {
		sw: {lat: 6.5546079, lng: 68.1113787},
		ne: {lat: 35.6745457, lng: 97.395561},
	},
	IOT: {
		sw: {lat: -7.6454079, lng: 71.036504},
		ne: {lat: -5.037066, lng: 72.7020157},
	},
	IRL: {
		sw: {lat: 51.222, lng: -11.0133788},
		ne: {lat: 55.636, lng: -5.6582363},
	},
	IRN: {
		sw: {lat: 24.8465103, lng: 44.0318908},
		ne: {lat: 39.7816502, lng: 63.3332704},
	},
	IRQ: {
		sw: {lat: 29.0585661, lng: 38.7936719},
		ne: {lat: 37.380932, lng: 48.8412702},
	},
	ISL: {
		sw: {lat: 63.0859177, lng: -25.0135069},
		ne: {lat: 67.353, lng: -12.8046162},
	},
	ISR: {
		sw: {lat: 29.4533796, lng: 34.2674994},
		ne: {lat: 33.3356317, lng: 35.8950234},
	},
	ITA: {
		sw: {lat: 35.2889616, lng: 6.6272658},
		ne: {lat: 47.0921462, lng: 18.7844746},
	},
	JAM: {
		sw: {lat: 16.5899443, lng: -78.5782366},
		ne: {lat: 18.7256394, lng: -75.7541143},
	},
	JEY: {
		sw: {lat: 49.1625179, lng: -2.254512},
		ne: {lat: 49.2621288, lng: -2.0104193},
	},
	JOR: {
		sw: {lat: 29.183401, lng: 34.8844372},
		ne: {lat: 33.3750617, lng: 39.3012981},
	},
	JPN: {
		sw: {lat: 20.2145811, lng: 122.7141754},
		ne: {lat: 45.7112046, lng: 154.205541},
	},
	KAZ: {
		sw: {lat: 40.5686476, lng: 46.4932179},
		ne: {lat: 55.4421701, lng: 87.3156316},
	},
	KEN: {
		sw: {lat: -4.8995204, lng: 33.9098987},
		ne: {lat: 4.62, lng: 41.899578},
	},
	KGZ: {
		sw: {lat: 39.1728437, lng: 69.2649523},
		ne: {lat: 43.2667971, lng: 80.2295793},
	},
	KHM: {
		sw: {lat: 9.4752639, lng: 102.3338282},
		ne: {lat: 14.6904224, lng: 107.6276788},
	},
	KIR: {
		sw: {lat: -7.0516717, lng: -179.1645388},
		ne: {lat: 7.9483283, lng: -164.1645388},
	},
	KNA: {
		sw: {lat: 16.895, lng: -63.051129},
		ne: {lat: 17.6158146, lng: -62.3303519},
	},
	KOR: {
		sw: {lat: 32.9104556, lng: 124.354847},
		ne: {lat: 38.623477, lng: 132.1467806},
	},
	KWT: {
		sw: {lat: 28.5243622, lng: 46.5526837},
		ne: {lat: 30.1038082, lng: 49.0046809},
	},
	LAO: {
		sw: {lat: 13.9096752, lng: 100.0843247},
		ne: {lat: 22.5086717, lng: 107.6349989},
	},
	LBN: {
		sw: {lat: 33.0479858, lng: 34.8825667},
		ne: {lat: 34.6923543, lng: 36.625},
	},
	LBR: {
		sw: {lat: 4.1555907, lng: -11.6080764},
		ne: {lat: 8.5519861, lng: -7.367323},
	},
	LBY: {
		sw: {lat: 19.5008138, lng: 9.391081},
		ne: {lat: 33.3545898, lng: 25.3770629},
	},
	LCA: {
		sw: {lat: 13.508, lng: -61.2853867},
		ne: {lat: 14.2725, lng: -60.6669363},
	},
	LIE: {
		sw: {lat: 47.0484291, lng: 9.4716736},
		ne: {lat: 47.270581, lng: 9.6357143},
	},
	LKA: {sw: {lat: 5.719, lng: 79.3959205}, ne: {lat: 10.035, lng: 82.0810141}},
	LSO: {
		sw: {lat: -30.6772773, lng: 27.0114632},
		ne: {lat: -28.570615, lng: 29.4557099},
	},
	LTU: {
		sw: {lat: 53.8967893, lng: 20.653783},
		ne: {lat: 56.4504213, lng: 26.8355198},
	},
	LUX: {
		sw: {lat: 49.4969821, lng: 4.9684415},
		ne: {lat: 50.430377, lng: 6.0344254},
	},
	LVA: {
		sw: {lat: 55.6746505, lng: 20.6715407},
		ne: {lat: 58.0855688, lng: 28.2414904},
	},
	MAC: {
		sw: {lat: 22.0766667, lng: 113.5281666},
		ne: {lat: 22.2170361, lng: 113.6301389},
	},
	MAF: {
		sw: {lat: 17.8963535, lng: -63.3605643},
		ne: {lat: 18.1902778, lng: -62.7644063},
	},
	MAR: {
		sw: {lat: 21.3365321, lng: -17.2551456},
		ne: {lat: 36.0505269, lng: -0.998429},
	},
	MCO: {
		sw: {lat: 43.7247599, lng: 7.4090279},
		ne: {lat: 43.7519311, lng: 7.4398704},
	},
	MDA: {
		sw: {lat: 45.4674139, lng: 26.6162189},
		ne: {lat: 48.4918695, lng: 30.1636756},
	},
	MDG: {
		sw: {lat: -25.6071002, lng: 43.2202072},
		ne: {lat: -11.9519693, lng: 50.4862553},
	},
	MDV: {
		sw: {lat: -0.9074935, lng: 72.3554187},
		ne: {lat: 7.3106246, lng: 73.9700962},
	},
	MEX: {
		sw: {lat: 14.3886243, lng: -118.59919},
		ne: {lat: 32.7186553, lng: -86.493266},
	},
	MHL: {
		sw: {lat: -0.5481258, lng: 163.4985095},
		ne: {lat: 14.4518742, lng: 178.4985095},
	},
	MKD: {
		sw: {lat: 40.8536596, lng: 20.4529023},
		ne: {lat: 42.3735359, lng: 23.034051},
	},
	MLI: {
		sw: {lat: 10.147811, lng: -12.2402835},
		ne: {lat: 25.001084, lng: 4.2673828},
	},
	MLT: {
		sw: {lat: 35.6029696, lng: 13.9324226},
		ne: {lat: 36.2852706, lng: 14.8267966},
	},
	MMR: {
		sw: {lat: 9.4399432, lng: 92.1719423},
		ne: {lat: 28.547835, lng: 101.1700796},
	},
	MNE: {
		sw: {lat: 41.7495999, lng: 18.4195781},
		ne: {lat: 43.5585061, lng: 20.3561641},
	},
	MNG: {
		sw: {lat: 41.5800276, lng: 87.73762},
		ne: {lat: 52.1496, lng: 119.931949},
	},
	MNP: {
		sw: {lat: 14.036565, lng: 144.813338},
		ne: {lat: 20.616556, lng: 146.154418},
	},
	MOZ: {
		sw: {lat: -26.9209427, lng: 30.2138197},
		ne: {lat: -10.3252149, lng: 41.0545908},
	},
	MRT: {
		sw: {lat: 14.7209909, lng: -17.068081},
		ne: {lat: 27.314942, lng: -4.8333344},
	},
	MSR: {
		sw: {lat: 16.475, lng: -62.450667},
		ne: {lat: 17.0152978, lng: -61.9353818},
	},
	MTQ: {
		sw: {lat: 14.3948596, lng: -61.2290815},
		ne: {lat: 14.8787029, lng: -60.8095833},
	},
	MUS: {
		sw: {lat: -20.725, lng: 56.3825151},
		ne: {lat: -10.138, lng: 63.7151319},
	},
	MWI: {
		sw: {lat: -17.1296031, lng: 32.6703616},
		ne: {lat: -9.3683261, lng: 35.9185731},
	},
	MYS: {
		sw: {lat: -5.1076241, lng: 105.3471939},
		ne: {lat: 9.8923759, lng: 120.3471939},
	},
	MYT: {
		sw: {lat: -13.0210119, lng: 45.0183298},
		ne: {lat: -12.6365902, lng: 45.2999917},
	},
	NAM: {
		sw: {lat: -28.96945, lng: 11.5280384},
		ne: {lat: -16.9634855, lng: 25.2617671},
	},
	NCL: {
		sw: {lat: -23.2217509, lng: 162.6034343},
		ne: {lat: -17.6868616, lng: 167.8109827},
	},
	NER: {
		sw: {lat: 11.693756, lng: 0.1689653},
		ne: {lat: 23.517178, lng: 15.996667},
	},
	NFK: {
		sw: {lat: -29.333, lng: 167.6873878},
		ne: {lat: -28.796, lng: 168.2249543},
	},
	NGA: {
		sw: {lat: 4.0690959, lng: 2.676932},
		ne: {lat: 13.885645, lng: 14.678014},
	},
	NIC: {
		sw: {lat: 10.7076565, lng: -87.901532},
		ne: {lat: 15.0331183, lng: -82.6227023},
	},
	NIU: {
		sw: {lat: -19.3548665, lng: -170.1595029},
		ne: {lat: -18.7534559, lng: -169.5647229},
	},
	NLD: {
		sw: {lat: 50.7295671, lng: 1.9193492},
		ne: {lat: 53.7253321, lng: 7.2274985},
	},
	NOR: {
		sw: {lat: 57.7590052, lng: 4.0875274},
		ne: {lat: 71.3848787, lng: 31.7614911},
	},
	NPL: {
		sw: {lat: 26.3477581, lng: 80.0586226},
		ne: {lat: 30.446945, lng: 88.2015257},
	},
	NRU: {
		sw: {lat: -0.5541334, lng: 166.9091794},
		ne: {lat: -0.5025906, lng: 166.9589235},
	},
	NZL: {
		sw: {lat: -52.8213687, lng: -179.059153},
		ne: {lat: -29.0303303, lng: 179.3643594},
	},
	OMN: {sw: {lat: 16.4649608, lng: 52}, ne: {lat: 26.7026737, lng: 60.054577}},
	PAK: {
		sw: {lat: 23.5393916, lng: 60.872855},
		ne: {lat: 37.084107, lng: 77.1203914},
	},
	PAN: {
		sw: {lat: 7.0338679, lng: -83.0517245},
		ne: {lat: 9.8701757, lng: -77.1393779},
	},
	PCN: {
		sw: {lat: -25.1306736, lng: -130.8049862},
		ne: {lat: -23.8655769, lng: -124.717534},
	},
	PER: {
		sw: {lat: -20.1984472, lng: -84.6356535},
		ne: {lat: -0.0392818, lng: -68.6519906},
	},
	PHL: {
		sw: {lat: 4.2158064, lng: 114.0952145},
		ne: {lat: 21.3217806, lng: 126.8072562},
	},
	PLW: {sw: {lat: 2.748, lng: 131.0685462}, ne: {lat: 8.222, lng: 134.7714735}},
	PNG: {
		sw: {lat: -13.1816069, lng: 136.7489081},
		ne: {lat: 1.8183931, lng: 151.7489081},
	},
	POL: {
		sw: {lat: 49.0020468, lng: 14.1229707},
		ne: {lat: 55.0336963, lng: 24.145783},
	},
	PRI: {
		sw: {lat: 17.9268695, lng: -67.271492},
		ne: {lat: 18.5159789, lng: -65.5897525},
	},
	PRK: {
		sw: {lat: 37.5867855, lng: 124.0913902},
		ne: {lat: 43.0089642, lng: 130.924647},
	},
	PRT: {
		sw: {lat: 29.8288021, lng: -31.5575303},
		ne: {lat: 42.1543112, lng: -6.1891593},
	},
	PRY: {
		sw: {lat: -27.6063935, lng: -62.6442036},
		ne: {lat: -19.2876472, lng: -54.258},
	},
	PSE: {
		sw: {lat: 31.2201289, lng: 34.0689732},
		ne: {lat: 32.5521479, lng: 35.5739235},
	},
	PYF: {
		sw: {lat: -28.0990232, lng: -154.9360599},
		ne: {lat: -7.6592173, lng: -134.244799},
	},
	QAT: {
		sw: {lat: 24.4707534, lng: 50.5675},
		ne: {lat: 26.3830212, lng: 52.638011},
	},
	REU: {
		sw: {lat: -21.3897308, lng: 55.2164268},
		ne: {lat: -20.8717136, lng: 55.8366924},
	},
	ROU: {
		sw: {lat: 43.618682, lng: 20.2619773},
		ne: {lat: 48.2653964, lng: 30.0454257},
	},
	RUS: {sw: {lat: 41.1850968, lng: 19.6389}, ne: {lat: 82.0586232, lng: 180}},
	RWA: {
		sw: {lat: -2.8389804, lng: 28.8617546},
		ne: {lat: -1.0474083, lng: 30.8990738},
	},
	SAU: {
		sw: {lat: 16.29, lng: 34.4571718},
		ne: {lat: 32.1543377, lng: 55.6666851},
	},
	SDN: {
		sw: {lat: 8.685278, lng: 21.8145046},
		ne: {lat: 22.224918, lng: 39.0576252},
	},
	SEN: {
		sw: {lat: 12.2372838, lng: -17.7862419},
		ne: {lat: 16.6919712, lng: -11.3458996},
	},
	SGP: {
		sw: {lat: 1.1304753, lng: 103.6920359},
		ne: {lat: 1.4504753, lng: 104.0120359},
	},
	SGS: {
		sw: {lat: -59.684, lng: -42.354739},
		ne: {lat: -53.3500755, lng: -25.8468303},
	},
	SHN: {
		sw: {lat: -16.23, lng: -5.9973424},
		ne: {lat: -15.704, lng: -5.4234153},
	},
	SJM: {
		sw: {lat: 70.6260825, lng: -9.6848146},
		ne: {lat: 81.028076, lng: 34.6891253},
	},
	SLB: {
		sw: {lat: -13.2424298, lng: 155.3190556},
		ne: {lat: -4.81085, lng: 170.3964667},
	},
	SLE: {
		sw: {lat: 6.755, lng: -13.5003389},
		ne: {lat: 9.999973, lng: -10.271683},
	},
	SLV: {
		sw: {lat: 12.976046, lng: -90.1790975},
		ne: {lat: 14.4510488, lng: -87.6351394},
	},
	SMR: {
		sw: {lat: 43.8937002, lng: 12.4033246},
		ne: {lat: 43.992093, lng: 12.5160665},
	},
	SOM: {
		sw: {lat: -1.8031969, lng: 40.98918},
		ne: {lat: 12.1889121, lng: 51.6177696},
	},
	SPM: {
		sw: {lat: 46.5507173, lng: -56.6972961},
		ne: {lat: 47.365, lng: -55.9033333},
	},
	SRB: {
		sw: {lat: 42.2322435, lng: 18.8142875},
		ne: {lat: 46.1900524, lng: 23.006309},
	},
	STP: {
		sw: {lat: -0.2135137, lng: 6.260642},
		ne: {lat: 1.9257601, lng: 7.6704783},
	},
	SUR: {
		sw: {lat: 1.8312802, lng: -58.070833},
		ne: {lat: 6.225, lng: -53.8433358},
	},
	SVK: {
		sw: {lat: 47.7314286, lng: 16.8331891},
		ne: {lat: 49.6138162, lng: 22.56571},
	},
	SVN: {
		sw: {lat: 45.4214242, lng: 13.3754696},
		ne: {lat: 46.8766816, lng: 16.5967702},
	},
	SWE: {
		sw: {lat: 55.1331192, lng: 10.5930952},
		ne: {lat: 69.0599699, lng: 24.1776819},
	},
	SWZ: {
		sw: {lat: -27.3175201, lng: 30.7908},
		ne: {lat: -25.71876, lng: 32.1349923},
	},
	SYC: {
		sw: {lat: -10.4649258, lng: 45.9988759},
		ne: {lat: -3.512, lng: 56.4979396},
	},
	SYR: {
		sw: {lat: 32.311354, lng: 35.4714427},
		ne: {lat: 37.3184589, lng: 42.3745687},
	},
	TCA: {
		sw: {lat: 20.9553418, lng: -72.6799046},
		ne: {lat: 22.1630989, lng: -70.8643591},
	},
	TCD: {sw: {lat: 7.44107, lng: 13.47348}, ne: {lat: 23.4975, lng: 24.0}},
	TGO: {
		sw: {lat: 5.926547, lng: -0.1439746},
		ne: {lat: 11.1395102, lng: 1.8087605},
	},
	THA: {
		sw: {lat: 5.612851, lng: 97.3438072},
		ne: {lat: 20.4648337, lng: 105.636812},
	},
	TJK: {
		sw: {lat: 36.6711153, lng: 67.3332775},
		ne: {lat: 41.0450935, lng: 75.1539563},
	},
	TKL: {
		sw: {lat: -9.6442499, lng: -172.7213673},
		ne: {lat: -8.3328631, lng: -170.9797586},
	},
	TKM: {
		sw: {lat: 35.129093, lng: 52.335076},
		ne: {lat: 42.7975571, lng: 66.6895177},
	},
	TLS: {
		sw: {lat: -9.5642775, lng: 124.0415703},
		ne: {lat: -8.0895459, lng: 127.5335392},
	},
	TON: {
		sw: {lat: -24.1034499, lng: -179.3866055},
		ne: {lat: -15.3655722, lng: -173.5295458},
	},
	TTO: {
		sw: {lat: 9.8732106, lng: -62.083056},
		ne: {lat: 11.5628372, lng: -60.2895848},
	},
	TUN: {
		sw: {lat: 30.230236, lng: 7.5219807},
		ne: {lat: 37.7612052, lng: 11.8801133},
	},
	TUR: {
		sw: {lat: 35.8076804, lng: 25.6212891},
		ne: {lat: 42.297, lng: 44.8176638},
	},
	TUV: {
		sw: {lat: -9.9939389, lng: 175.1590468},
		ne: {lat: -5.4369611, lng: 178.7344938},
	},
	TWN: {
		sw: {lat: 10.374269, lng: 114.3599058},
		ne: {lat: 26.4372222, lng: 122.297},
	},
	TZA: {
		sw: {lat: -11.761254, lng: 29.3269773},
		ne: {lat: -0.9854812, lng: 40.6584071},
	},
	UGA: {
		sw: {lat: -1.4823179, lng: 29.573433},
		ne: {lat: 4.2340766, lng: 35.000308},
	},
	UKR: {
		sw: {lat: 44.184598, lng: 22.137059},
		ne: {lat: 52.3791473, lng: 40.2275801},
	},
	UMI: {
		sw: {lat: 6.1779744, lng: -162.6816297},
		ne: {lat: 6.6514388, lng: -162.1339885},
	},
	URY: {
		sw: {lat: -35.7824481, lng: -58.4948438},
		ne: {lat: -30.0853962, lng: -53.0755833},
	},
	USA: {sw: {lat: 24.9493, lng: -125.0011}, ne: {lat: 49.5904, lng: -66.9326}},
	UZB: {
		sw: {lat: 37.1821164, lng: 55.9977865},
		ne: {lat: 45.590118, lng: 73.1397362},
	},
	VAT: {
		sw: {lat: 41.9002044, lng: 12.4457442},
		ne: {lat: 41.9073912, lng: 12.4583653},
	},
	VCT: {
		sw: {lat: 12.5166548, lng: -61.6657471},
		ne: {lat: 13.583, lng: -60.9094146},
	},
	VEN: {
		sw: {lat: 0.647529, lng: -73.3529632},
		ne: {lat: 15.9158431, lng: -59.5427079},
	},
	VGB: {
		sw: {lat: 17.623468, lng: -65.159094},
		ne: {lat: 18.464984, lng: -64.512674},
	},
	VIR: {
		sw: {lat: 17.623468, lng: -65.159094},
		ne: {lat: 18.464984, lng: -64.512674},
	},
	VNM: {
		sw: {lat: 8.1790665, lng: 102.14441},
		ne: {lat: 23.393395, lng: 114.3337595},
	},
	VUT: {
		sw: {lat: -20.4627425, lng: 166.3355255},
		ne: {lat: -12.8713777, lng: 170.449982},
	},
	WLF: {
		sw: {lat: -14.5630748, lng: -178.3873749},
		ne: {lat: -12.9827961, lng: -175.9190391},
	},
	WSM: {
		sw: {lat: -14.2770916, lng: -173.0091864},
		ne: {lat: -13.2381892, lng: -171.1929229},
	},
	YEM: {sw: {lat: 11.9084802, lng: 41.60825}, ne: {lat: 19.0, lng: 54.7389375}},
	ZAF: {
		sw: {lat: -47.1788335, lng: 16.3335213},
		ne: {lat: -22.1250301, lng: 38.2898954},
	},
	ZMB: {
		sw: {lat: -18.0765945, lng: 21.9993509},
		ne: {lat: -8.2712822, lng: 33.701111},
	},
	ZWE: {
		sw: {lat: -22.4241096, lng: 25.2373},
		ne: {lat: -15.6097033, lng: 33.0683413},
	},
};
